import styled, { keyframes } from 'styled-components';

import {
  TextField,
  CircularProgress,
} from '@material-ui/core';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  animation: ${appearFromLeft} 1s;

  /* 100% da parte visível da tela - total da altura */
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1;

  min-height: 90vh;
  position: relative;

  padding: 20px 50px 30px 50px;
`;

export const Background = styled.img`
  position: absolute;
  opacity: 0.15;

  @media(max-width: 800px) {
    display: none;
    /* top: 80px;
    left: 0;
    opacity: 0.1;

    height: 360px; */
  }
  @media(min-width: 799px) {
    /* display: flex; */
    margin-right: 20px;

    bottom: 0;
    right: 0;

    height: 700px;
  }

  z-index: -50 !important;

`;

export const LinhaPesquisa = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Pesquisa = styled(TextField)`
  width: auto;
  flex: 1;
`;

export const PesquisaTotais = styled.div`
  /* margin-left: 4px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const TotalPesquisa = styled.p`
  font-size: 18px;
  color: var(--cor-login-contorno-input);
  margin-top: 8px;
  text-align: center;
`;

export const DataPesquisa = styled.p`
  font-size: 18px;
  color: var(--cor-login-contorno-input);
  margin-top: 4px;
  text-align: center;
`;

export const ListaCartoes = styled.div`

  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;

  animation: ${appearFromLeft} 0.3s;
`;

export const Cartao = styled.div`
  animation: ${appearFromLeft} 1s;

  margin-top: 20px;
  /* width: 100%; */

  -webkit-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  -moz-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);

  border-radius: 15px;
  border: 2px solid whitesmoke;

  padding: 8px 16px 16px;

  display: flex;
  flex-direction: column;

  transition: all ease 0.3s;

  &:hover {
    background-color: #f2f2f2;
  }
  z-index: 1;

  p {
    color: #999999;
    margin-top: 4px;

    /* a partir do segundo p */
    /* & + p {
      margin-top: 4px;
    } */
  }
`;

export const CabecalhoCartao = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  h2 {
    color: var(--cor-fundo);
    flex: 1;
    margin-right: 10px;
    margin-top: 4px;
    font-size: 18px;
  }

  p {
    color: var(--cor-login-contorno-input);
    font-size: 18px;
  }

  z-index: 1;
`;

export const SeparadorCartao = styled.div`
  margin-top: 5px;
  border-bottom: 6px solid var(--cor-login-contorno-input);
  border-radius: 3px;
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Loading = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: var(--cor-login-contorno-input);
  }
`;

export const CorpoCartao = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;

  z-index: 1;

  margin-top: 5px;

  div {
    margin-top: 6px;
  }
`;

export const TituloCartao = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 2px;

  z-index: 2;

  -webkit-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.45);
  box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.45);

  border: 2px solid var(--cor-fundo);
  border-radius: 12px;

  padding: 5px;

  /* P:not(:first-child) {
    margin-left: 10px;
  } */

  p {
    font-size: 14px;
  }

  &:hover {
    background-color: var(--cor-fundo);
    p {
      color: whitesmoke;
    }
  }
`;
