import React, { useEffect, useState, useCallback } from 'react';
import { FiAlertTriangle, FiCheckCircle, FiShieldOff } from 'react-icons/fi';
import { format, isValid, parseISO } from 'date-fns';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import apiLiberacao from '../../services/apiLiberacao';

import IClientesCadastrados from '../../services/IClientesCadastrados';
import IClienteBloquear from '../../services/IClienteBloquear';

import {
  Container,
  Background,
  Pesquisa,
  Cartao,
  CabecalhoCartao,
  AreaBotoes,
  Botao,
  LoadingContainer,
  Loading,
} from './styles';

import backgroundImg from '../../assets/logo-sizex.png';

// import LeftSideBar from '../../animations/LeftSideBar';

const Liberacao: React.FC = () => {
  const { usuario, senha } = useAuth();
  const { addToast } = useToast();

  const [clientes, setClientes] = useState<IClientesCadastrados[]>([]);
  const [clientesExibir, setClientesExibir] = useState<IClientesCadastrados[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let token: string = '';
    setLoading(true);
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      // console.log(response);
      token = response.data.access_token;
      // console.log(token);

      apiLiberacao.get('/sizex/api/liberacao/todas-pessoas-clientes', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        // console.log(resp);
        if (resp.data.sucesso) {
          // console.log(resp.data.dados);
          setClientes(resp.data.dados);
        }
        setLoading(false);
      });
    }).catch((error) => {
      setLoading(false);
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [senha, usuario, addToast]);

  const aoPesquisar = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const textoPesquisa: string = e.target.value.toUpperCase();

    if (textoPesquisa.length >= 3) {
      const clientesFiltrados = clientes.filter(cliente => cliente.ativo && (cliente.fantasia.toUpperCase().includes(textoPesquisa) || cliente.nome.toUpperCase().includes(textoPesquisa)));
      setClientesExibir(clientesFiltrados);
    } else {
      setClientesExibir([]);
    }
  }, [clientes]);

  const bloquear = useCallback((cliente: IClientesCadastrados) => {
    const clienteBloquear: IClienteBloquear = {
      nomeFantasia: cliente.fantasia ? cliente.fantasia : cliente.nome,
      cnpjCpf: cliente.cnpjCpf,
      dataUltVencto: new Date(),
    };

    let token: string = '';
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;

      apiLiberacao.post('/sizex/api/liberacao/gravar', clienteBloquear, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          addToast({
            type: "success",
            title: 'Cliente Bloqueado!!!',
          });
        }
      }).catch((error) => {
        addToast({
          type: "error",
          title: 'Falha ao bloquear cliente!!!',
        });
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Falha ao bloquear cliente!!!',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario]);

  const desbloquear = useCallback((cliente: IClientesCadastrados) => {
    const cnpj: string = cliente.cnpjCpf.replace('.','').replace('.','').replace('-','').replace('/','');

    let token: string = '';
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;

      apiLiberacao.post('/sizex/api/liberacao/desbloquear-axios/' + cnpj, null, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          addToast({
            type: "success",
            title: 'Cliente Desbloqueado!!!',
          });
        }
      }).catch((error) => {
        addToast({
          type: "error",
          title: 'Falha ao desbloquear cliente!!!',
        });
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Falha ao desbloquear cliente!!!',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario]);

  const cadastrar = useCallback((cliente: IClientesCadastrados) => {
    const clienteBloquear: IClienteBloquear = {
      nomeFantasia: cliente.fantasia ? cliente.fantasia : cliente.nome,
      cnpjCpf: cliente.cnpjCpf,
      dataUltVencto: new Date(),
    };

    let token: string = '';
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;

      apiLiberacao.post('/sizex/api/liberacao/cadastrar', clienteBloquear, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          addToast({
            type: "success",
            title: 'Cliente Cadastrado!!!',
          });
        } else {
          if (resp.data.mensagens) {
            if (resp.data.mensagens.length > 0) {
              // console.log(resp.data.mensagens[0].texto);
              addToast({
                type: "info",
                title: resp.data.mensagens[0].texto,
              });
            }
          }
        }
      }).catch((error) => {
        console.log(error);
        addToast({
          type: "error",
          title: 'Falha ao cadastrar cliente!!!',
        });
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Falha ao cadastrar cliente!!!',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario]);

  const descadastrar = useCallback((cliente: IClientesCadastrados) => {
    const cnpj: string = cliente.cnpjCpf.replace('.','').replace('.','').replace('-','').replace('/','');
    console.log(cnpj);

    let token: string = '';
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;

      apiLiberacao.get('/sizex/api/liberacao/descadastrar/' + cnpj, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        if (resp.data.sucesso) {
          addToast({
            type: "success",
            title: 'Cliente Excluído!!!',
          });
        }
      }).catch((error) => {
        addToast({
          type: "error",
          title: 'Falha ao descadastrar cliente!!!',
        });
      });
    }).catch((error) => {
      addToast({
        type: 'info',
        title: 'Falha ao descadastrar cliente!!!',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario]);

  const iconeStatus = useCallback((item: IClientesCadastrados) => {
    switch (item.statusLiberacao) {
      case "LIBERADO": return <FiCheckCircle size={50} color="#1c9434" />;
      case "NAO CADASTRADO": return <FiShieldOff size={50} color="#ba1e26"/>;
      case "BLOQUEADO NA API": return <FiShieldOff size={50} color="#ba1e26"/>;
      default: return <FiAlertTriangle size={50} color="#dbdb0d"/>;
    }
  }, []);

  const dataFormatada = useCallback((data: string) => {
    if (data) {
      if (data !== '') {
        const dataConvertida = parseISO(data);
        if (isValid(dataConvertida)) {
          return format(dataConvertida, 'dd/MM/yyyy');
        }
      }
    }

    return '';
  }, []);

  return (
    <>
      {/* <LeftSideBar /> */}

      <Container>
        <Background src={backgroundImg} alt="Fundo Sizex" />
        <Pesquisa
          variant="outlined"
          margin="normal"
          // required
          fullWidth
          id="pesquisa"
          label="Pesquisar"
          name="pesquisa"
          // autoComplete="email"
          autoFocus
          // inputRef={inputNomeRef}
          onChange={aoPesquisar}
          autoComplete="off"
        />

        {/* código pra testar quanto demora pra carregar clientes da api */}
        {/* {clientes.map(cliente => (
          <h1>{cliente.nomeFantasia}</h1>
        ))} */}

        {loading && (
          <LoadingContainer>
            <Loading size={60} />
          </LoadingContainer>
        )}

        {clientesExibir.map(cliente => (
          <Cartao key={cliente.id}>
            <CabecalhoCartao>
              <h2>{cliente.fantasia}</h2>
              {iconeStatus(cliente)}
              {/* <FiAlertTriangle size={30} color="#595959"/> */}
            </CabecalhoCartao>

            <p>{cliente.nome}</p>
            <p>CNPJ: {cliente.cnpjCpf}</p>
            <p>{cliente.enderecoFormatado}</p>
            <p>{cliente.cidade}-{cliente.estado}</p>
            <p>ÚLTIMA CONSULTA: {dataFormatada(cliente.ultimaConsultaLiberacao)}</p>
            {/* <p>PRÓXIMA: {cliente.proximaConsulta}</p> */}

            <AreaBotoes>
              <Botao
                type="button"
                variant="contained"
                onClick={() => bloquear(cliente)}
              >
                BLOQUEAR
              </Botao>
              <Botao
                type="button"
                variant="contained"
                onClick={() => desbloquear(cliente)}
              >
                DESBLOQUEAR
              </Botao>
              <Botao
                type="button"
                variant="contained"
                onClick={() => cadastrar(cliente)}
              >
                CADASTRAR
              </Botao>
              <Botao
                type="button"
                variant="contained"
                onClick={() => descadastrar(cliente)}
              >
                DESCADASTRAR
              </Botao>
            </AreaBotoes>
          </Cartao>
        ))}
      </Container>
    </>
  );
}

export default Liberacao;
