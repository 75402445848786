import React from 'react';
import { Switch, useLocation  } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Liberacao from '../pages/Liberacao';
import Cobranca from '../pages/Cobranca';
import LeftSideBar from '../animations/LeftSideBar';

const Routes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <LeftSideBar />}
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/liberacao" component={Liberacao} isPrivate />
        <Route path="/cobranca" component={Cobranca} isPrivate />
      </Switch>
    </>
  )
}

export default Routes;
