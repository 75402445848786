import styled, { keyframes } from 'styled-components';

import {
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  animation: ${appearFromLeft} 1s;

  /* 100% da parte visível da tela - total da altura */
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1;

  min-height: 90vh;
  position: relative;

  padding: 20px 50px 30px 50px;
`;

export const Background = styled.img`
  position: absolute;
  opacity: 0.15;

  @media(max-width: 800px) {
    display: none;
    /* top: 80px;
    left: 0;
    opacity: 0.1;

    height: 360px; */
  }
  @media(min-width: 799px) {
    /* display: flex; */
    margin-right: 20px;

    bottom: 0;
    right: 0;

    height: 700px;
  }

  z-index: -50 !important;

`;

export const Pesquisa = styled(TextField)`

`;


export const Cartao = styled.div`
  animation: ${appearFromLeft} 0.3s;

  margin-top: 20px;
  width: 100%;

  -webkit-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  -moz-box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);
  box-shadow: 3px 5px 12px -1px rgba(0,0,0,0.4);

  border-radius: 15px;
  border: 2px solid whitesmoke;

  padding: 15px;

  display: flex;
  flex-direction: column;

  transition: all ease 0.3s;

  &:hover {
    background-color: #f2f2f2;
  }
  z-index: 1;

  p {
    color: #999999;
    margin-top: 4px;

    /* a partir do segundo p */
    /* & + p {
      margin-top: 4px;
    } */
  }
`;

export const CabecalhoCartao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h2 {
    color: #595959;
    flex: 1;
    margin-right: 10px;
    margin-top: 4px;
  }

  svg {
  }
  z-index: 1;
`;

export const AreaBotoes = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Botao = styled(Button)`
  margin-top: 4px !important;
  background-color: var(--cor-login-botao) !important;
  color: var(--cor-login-fundo) !important;

  width: 145px !important;

  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
  }

  /* & + button {
    margin-left: 10px;
  } */
`;

export const LoadingContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Loading = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: var(--cor-login-contorno-input);
  }
`;
