import axios from 'axios';

const apiLiberacao = axios.create({
  // Local:
  // baseURL: 'http://localhost:52619/',
  // GoDaddy:
  // baseURL: 'http://50.63.13.60:80/liberacao/',
  // baseURL: 'http://api-lib.sizex.com.br/'
  // SmartAspNet:
  // baseURL: 'http://sizexserver-001-site1.htempurl.com/liberacao/',
  // Locaweb:
  baseURL: 'http://vpsw3286.publiccloud.com.br//liberacao/',
});

export default apiLiberacao;
