import React, { useCallback, useRef, FormEvent, useState } from 'react';

import {
  MyTextField,
  Content,
  Form,
  MyButton,
  Container,
  LogoSizex,
  Background,
  LoadingContainer,
  // Loading,
  LoadingLinear,
} from './styles';

import logoImg from '../../assets/logo-sizex2.jpg';
import backgroundImg from '../../assets/logo-sizex.png';

import { useToast } from '../../hooks/ToastContext';
import { useAuth } from '../../hooks/AuthContext';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const inputNomeRef = useRef<HTMLInputElement>(null);
  const inputSenhaRef = useRef<HTMLInputElement>(null);

  const loadingFalse = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault(); // Evitar que o submit recarregue a tela
    setLoading(true);

    const nome = inputNomeRef?.current?.value.toString().toUpperCase();
    const senha = inputSenhaRef?.current?.value.toString().toUpperCase();

    if (nome && senha) {
      signIn({
        nome: nome,
        senha: senha,
      });
    } else {
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Informe usuário e senha.',
      });
      setLoading(false);
    }
  }, [signIn, addToast, setLoading]);

  return (
    <Container>
      <Background src={backgroundImg} alt="Fundo Sizex" />
      <Content>
        <LogoSizex src={logoImg} alt="Sizex Logo"/>

        <Form noValidate onSubmit={handleSubmit}>
          <MyTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="usuario"
            label="Usuário"
            name="usuario"
            // autoComplete="email"
            autoFocus
            inputRef={inputNomeRef}
            onFocus={loadingFalse}
            onChange={loadingFalse}
            autoComplete="off"
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
            inputRef={inputSenhaRef}
            onFocus={loadingFalse}
            onChange={loadingFalse}
          />

          <MyButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Login
          </MyButton>

          {loading && (
            <LoadingContainer>
              {/* <Loading /> */}
              <LoadingLinear />
            </LoadingContainer>
          )}

        </Form>
      </Content>

    </Container>
  );
}

export default Login;
