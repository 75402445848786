import React, { useState, useEffect, useCallback } from 'react';
import { format, isValid, parseISO } from 'date-fns';

import { useAuth } from '../../hooks/AuthContext';
import { useToast } from '../../hooks/ToastContext';

import apiLiberacao from '../../services/apiLiberacao';

import ICobranca from '../../services/ICobranca';

import {
  Container,
  Background,
  LinhaPesquisa,
  Pesquisa,
  PesquisaTotais,
  TotalPesquisa,
  DataPesquisa,
  ListaCartoes,
  Cartao,
  CabecalhoCartao,
  SeparadorCartao,
  LoadingContainer,
  Loading,
  CorpoCartao,
  TituloCartao,
} from './styles';

import backgroundImg from '../../assets/logo-sizex.png';

// import LeftSideBar from '../../animations/LeftSideBar';

const Cobranca: React.FC = () => {
  const { usuario, senha } = useAuth();
  const { addToast } = useToast();

  const [cobrancas, setCobrancas] = useState<ICobranca[]>([]);
  const [cobrancasExibir, setCobrancasExibir] = useState<ICobranca[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [totalCobranca, setTotalCobranca] = useState(0);
  const [dataUpload, setDataUpload] = useState(new Date());

  useEffect(() => {
    let token: string = '';
    setLoading(true);
    apiLiberacao.post('/sizex/api/obtertoken', `userName=${usuario}&password=${senha}&grant_type=password`).then((response) => {
      token = response.data.access_token;

      apiLiberacao.get('/sizex/api/liberacao/cobranca/buscar', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }).then((resp) => {
        // console.log(resp);
        if (resp.data.sucesso) {
          // console.log(resp.data.dados);
          const registros: ICobranca[] = resp.data.dados;
          setCobrancas(registros);
          setCobrancasExibir(registros);
          setTotalCobranca(registros.reduce((sum, current) => sum + current.total, 0))

          if (registros) {
            if (registros.length > 0) {
              setDataUpload(registros[0].dataUpload);
            }
          }
        }
        setLoading(false);
      });
    }).catch((error) => {
      setLoading(false);
      addToast({
        type: 'info',
        title: 'Erro na autenticação',
        description: 'Ocorreu algum erro na autenticação do usuário no servidor.',
      });
    })
  }, [addToast, senha, usuario]);

  const aoPesquisar = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const textoPesquisa: string = e.target.value.toUpperCase();

    let cobrancasFiltradas: ICobranca[] = [];
    if (textoPesquisa.length >= 3) {
      // setCobrancasExibir([]);
      cobrancasFiltradas = cobrancas.filter(cobranca => cobranca.nomeFantasia.toUpperCase().includes(textoPesquisa));
      setCobrancasExibir(cobrancasFiltradas);
    } else {
      cobrancasFiltradas = cobrancas;
      setCobrancasExibir(cobrancas);
    }
    setTotalCobranca(cobrancasFiltradas.reduce((sum, current) => sum + current.total, 0))
  }, [cobrancas]);

  const dataFormatada = useCallback((data: string) => {
    if (data) {
      if (data !== '') {
        const dataConvertida = parseISO(data);
        if (isValid(dataConvertida)) {
          return format(dataConvertida, 'dd/MM/yyyy');
        }
      }
    }

    return '';
  }, []);


  return (
    <>
      {/* <LeftSideBar /> */}

      <Container>
        <Background src={backgroundImg} alt="Fundo Sizex" />

        <LinhaPesquisa>

        <PesquisaTotais>
            <TotalPesquisa>R$ {totalCobranca.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TotalPesquisa>
            <DataPesquisa>ATUALIZAÇÃO: {dataFormatada(dataUpload.toString())}</DataPesquisa>
          </PesquisaTotais>

          <Pesquisa
            variant="outlined"
            margin="normal"
            // required
            // fullWidth
            id="pesquisa"
            label="Pesquisar"
            name="pesquisa"
            // autoComplete="email"
            autoFocus
            // inputRef={inputNomeRef}
            onChange={aoPesquisar}
            autoComplete="off"
          />
        </LinhaPesquisa>

        {loading && (
          <LoadingContainer>
            <Loading size={60} />
          </LoadingContainer>
        )}


        <ListaCartoes>
          {cobrancasExibir.map(cobranca => (
            <Cartao key={cobranca.idCliente}>
              <CabecalhoCartao>
                <h2>{cobranca.nomeFantasia}</h2>
                <p>R$ {cobranca.total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
              </CabecalhoCartao>
              <SeparadorCartao />
              <CorpoCartao>
                {cobranca.titulos.map(titulo => (
                  <TituloCartao key={titulo.idTitulo}>
                    <p>{titulo.especie}</p>
                    <p>{dataFormatada(titulo.vencimento.toString())}</p>
                    <p>R$ {titulo.valorPago.toLocaleString(navigator.language, { minimumFractionDigits: 2 })} / R$ {titulo.valor.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</p>
                  </TituloCartao>
                ))}
              </CorpoCartao>
            </Cartao>
          ))}
        </ListaCartoes>


      </Container>
    </>
  );
}

export default Cobranca;
