import styled, { keyframes } from "styled-components";

import {
  TextField,
  Button,
  // CircularProgress,
  LinearProgress
} from "@material-ui/core";

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromLeftBackground = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 0.15;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background-color: var(--cor-login-fundo);

  /* 100% da parte visível da tela - total da altura */
  height: 100vh;

  display: flex;
  justify-content: center;

  /* para o celular - tava ficando parte do fundo branco quando clicar em um campo pra digitar */
  min-height: 550px;

  position: relative;
`;

export const Background = styled.img`
  position: absolute;
  opacity: 0.15;

  animation: ${appearFromLeftBackground} 1s;

  margin-top: 100px;

  height: 350px;

  /* deixa imagem por trás, senão não consegue clicar nos componentes */
  z-index: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  animation: ${appearFromLeft} 1s;

  /* max-width: 700px; */

  /* para o celular - tava ficando parte do fundo branco quando clicar em um campo pra digitar */
  max-height: 550px;

  /* coloca na frente da imagem de fundo */
  z-index: 1;
`;

export const LogoSizex = styled.img`
  margin-top: 48px;
  width: 200px;
  height: 210px;
`;

export const Form = styled.form`
  /* width: 100%; */
  max-width: 400px;
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const MyTextField = styled(TextField)`
  /* contorno do input */
  & .MuiOutlinedInput-root {
    fieldset {
      &.MuiOutlinedInput-notchedOutline {
        border-color: var(--cor-login-contorno-input) !important;
      }
    }
  }
  /* Contorno do input no hover */
  &:hover {
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: var(--cor-login-contorno-input-hover) !important;
        }
      }
    }
  }
  /* contorno quando foca no input */
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: var(--cor-login-contorno-input) !important;
    }
  }

  /* cor da fonte do input */
  & .MuiOutlinedInput-input {
    color: var(--cor-login-fonte) !important;
    /* letra maiúscula no input */
    text-transform: uppercase;
  }


  /* label quando preenchido */
  & label.MuiFormLabel-filled {
    color: var(--cor-login-contorno-input) !important;
  }

  /* label em cima do input */
  & label.Mui-focused {
    color: var(--cor-login-fonte) !important;
  }

  /* seria o placeholder */
  > label {
    color: var(--cor-login-placeholder-input);
  }
`;

export const MyButton = styled(Button)`
  margin-top: 16px !important;
  background-color: var(--cor-login-botao) !important;
  color: var(--cor-login-fundo) !important;

  &:hover {
    background-color: var(--cor-login-botao-hover) !important;
    /* color: var(--cor-login-fonte) !important; */
  }
`;

export const LoadingContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

// export const Loading = styled(CircularProgress)`
//   &.MuiCircularProgress-colorPrimary {
//     color: var(--cor-login-contorno-input);
//   }
// `;

export const LoadingLinear = styled(LinearProgress)`
  width: 100%;

  &.MuiLinearProgress-colorPrimary {
    background-color: var(--cor-login-contorno-input-hover) !important;
  }
  & > div {
    background-color: var(--cor-login-botao) !important;
  }
`;
